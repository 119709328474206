// TODO: Faizan Rename LoveOurClients to OurClient and remove existing OurClient.

import config from 'config';
import dynamic from 'next/dynamic';
import { Fragment } from 'react';

import Header from 'components/Common/header/Header';
import AboutSection from 'components/homePage/web/AboutSection';
import CorporateEvents from 'components/homePage/web/CorporateEvents';
import EventGallery from 'components/homePage/web/EventGallery';
import EventsGlimpses from 'components/homePage/web/EventsGlimpses';
import FeaturedCategories from 'components/homePage/web/FeaturedCategories';
import FeaturedEvents from 'components/homePage/web/FeaturedEvents';
import HaflaBlog from 'components/homePage/web/HaflaBlog';
import HaflaExperience from 'components/homePage/web/HaflaExperience';
import OurClients from 'components/homePage/web/LoveOurClients';
import TrendingCollection from 'components/homePage/web/TrendingCollection';
import WhyChooseHafla from 'components/homePage/web/WhyChooseHafla';
import Layout from 'components/layout/DefaultLayout';
import corporateEventList from 'data/corporate-events';
import eventGlimpsesList from 'data/event-glimpses';
import { featuredEvents } from 'data/featured-events';
import haflaServices from 'data/why-choose-hafla';
import { GTM_EVENTS_CTA_NAME } from 'lib/gtm';
import { RibbonContainer } from 'molecules';

const eventGalleryConfig = {
  eventCategory: 'home',
  title: 'homeTitle'
};

const HowDoesItWork = dynamic(
  () => import('components/homePage/HowDoesItWork'),
  {
    ssr: false
  }
);

const EventGalleryWeb = (data) => (
  <div className='bg-gray90 md:bg-ghost-white pt-7 pb-5 md:py-20'>
    <EventGallery {...data} />
  </div>
);

const WebHome = ({ data, bannerTranslation }) => (
  <Fragment>
    <Header
      homeScreen={true}
      categories={data.parentCategories}
    />
    <Layout
      paramData={{
        bannerTranslate: bannerTranslation,
        translate: bannerTranslation,
        eventCategory: 'home',
        homeScreen: true
      }}
    >
      <FeaturedEvents featuredEvents={featuredEvents} />
      <FeaturedCategories />
      <RibbonContainer
        title={'Premium Event Planning Services'}
        buttonTitle={'Book a free consultation'}
        buttonUrl={config.premiumEventPlanningLink}
        ctaName={GTM_EVENTS_CTA_NAME.BOOK_FREE_CONSULTATION}
      />
      <TrendingCollection collections={data?.collections} />
      <CorporateEvents {...{ eventList: corporateEventList }} />
      <EventsGlimpses {...{ eventGlimpsesList }} />
      <RibbonContainer
        title={'Become a Partner'}
        buttonTitle={'Start Here'}
        buttonUrl='/partners/basic-info'
        subTitle='3 easy steps to join the Hafla!'
        isOpenInSamePage
        ctaName={GTM_EVENTS_CTA_NAME.BECOME_PARTNER}
      />
      <WhyChooseHafla {...{ haflaServices }} />
      <HaflaExperience />
      <OurClients />
      <HowDoesItWork />
      <HaflaBlog posts={data?.blogPost} />
      <EventGalleryWeb {...{ ...data, ...eventGalleryConfig }} />
      <AboutSection />
    </Layout>
  </Fragment>
);

export default WebHome;
