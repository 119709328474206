import { SectionHeading } from 'atoms';

const tableValues = [
  {
    title: 'price & quality',
    data: [
      ['Trusted partners', 'Competitive prices'],
      [''],
      ['Uncertain quality', 'Hidden costs']
    ]
  },
  {
    title: 'Convenience',
    data: [
      ['Free event planning consultation', 'Personalized experience'],
      [''],
      ['Limited inventory', 'Little guidance & support']
    ]
  },
  {
    title: 'Customer Experience',
    data: [
      ['Dedicated team for prompt support', 'One stop-shop'],
      [''],
      ['Time-consuming', 'High friction']
    ]
  },
  {
    title: 'Tech',
    data: [
      ['Easy self-checkout', 'One payment method'],
      [''],
      ['Not easily accessible', 'Lack of smart solutions']
    ]
  }
];

const TableHeader = () => (
  <>
    <div></div>
    <div className='hafla-experience backgroundColor text-center'>
      <div>The hafla experience</div>
    </div>
    <div></div>
    <div className='typical-experience background-color-experience text-center'>
      A typical experience
    </div>
  </>
);

const TableRow = ({ title, tableData }) => (
  <>
    <div className='uppercase grid-title bg-gray90 bg-ghost-white'>{title}</div>
    {
      // TODO: break it into multiple components
    }
    {tableData.map((values, index) =>
      values.length > 1 ? (
        <div
          key={index}
          className='grid-item bg-gray90 bg-ghost-white'
        >
          {values.map((rootData, indexRoot) => (
            <li key={`${index}_${indexRoot}`}>{rootData}</li>
          ))}
        </div>
      ) : (
        <div
          key={index}
          className='grid-for-blank bg-gray90 bg-ghost-white'
        ></div>
      )
    )}
  </>
);

const TableData = () =>
  tableValues.map(({ title, data }, index) => (
    <TableRow
      key={index}
      {...{ title, tableData: data }}
    />
  ));

const HaflaExperience = () => (
  <div className='bg-gray90 bg-ghost-white pt-10 pb-10 '>
    <div className='m-0 m-auto w-4/5'>
      <SectionHeading
        title='The Hafla Experience'
        subTitle='What you get is what you see.'
      />
      <div className='foot-background Montserrat'>
        <div className='bg-white grid-container bg-gray90 bg-ghost-white'>
          <TableHeader />
          <TableData />
        </div>
      </div>
    </div>
  </div>
);

export default HaflaExperience;
